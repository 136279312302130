/**
 * Manage breadcrump name
 */
const routes = {
    '/': 'Accueil',
    '/configuration' : 'Configuration',
    '/dashboard': 'Dashboard',
    '/prices' : 'Gestion des tarifs',
    '/commissions' : 'Gestion des commissions',
    '/products' : 'Gestion des produits',
    '/articles' : 'Gestion des articles',
    '/customers': 'Clients',
    '/members' : 'Membres', 
    '/account': 'Mon compte',
};
export default routes;
