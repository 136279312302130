import { createAction } from 'redux-friends';

import MyTypes from './types';
import { SET_ARTICLES } from './constants';
import * as articleService from '../../../../services/catalog/article';

export const setArticles = createAction(MyTypes[SET_ARTICLES]);

export const fetchArticles = (state = {}) => async dispatch => {
	try {
		const articles = (await articleService.search(state))['hydra:member'];
		dispatch(setArticles(articles));
	} catch (error) {
		console.error({ error });
	}
};
