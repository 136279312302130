import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

const SidebarHeader = ({ user }) => (
	<div className="sidebar-header">
		<div>
			<strong>{user.name}</strong>
		</div>
		<div className="text-muted">
			<small>{user.email}</small>
		</div>
	</div>
);

const mapStateToProps = state => ({
	user: state.user,
});

SidebarHeader.propTypes = {
	user: PropTypes.shape({ name: PropTypes.string, email: PropTypes.string }).isRequired,
};

export default connect(mapStateToProps)(SidebarHeader);
