import MyTypes from './types';
import { SET_ARTICLES } from './constants';
import { createReducer } from 'redux-friends';

const defaultState = [];

const behaviors = {
	[MyTypes[SET_ARTICLES]]: (state, { payload }) => payload,
};

export default createReducer(behaviors, defaultState);
