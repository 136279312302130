import React, {Component} from 'react';
import {withRouter} from 'react-router'
import {
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown,
    Progress,
} from 'reactstrap';
import * as sessionService from '../../services/session';

class HeaderDropdownCms extends Component {

    constructor(props) {

        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            dropdownOpen: false,
        };


    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }


    render() {

        const {history} = this.props;


        return (
            <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle nav>
                    <i className="fa fa-file" /> Cms
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem onClick={() => history.push('/pages')}>Pages</DropdownItem>
                    <DropdownItem onClick={() => history.push('/posts')}>Articles</DropdownItem>
                    <DropdownItem onClick={() => history.push('/medias')}>Medias</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default withRouter(HeaderDropdownCms);
