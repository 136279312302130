import { createTypes } from 'redux-friends';

export const SUBSCRIPTIONS_RK = 'subscriptions';


export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const SET_ACTIVE_FILTERS = 'SET_ACTIVE_FILTERS';
export const SET_FILTERS = 'SET_FILTERS';

export const SUBSCRIPTION_SIDEBAR_ID = 'subscription';
export const SUBSCRIPTION_SIDEBAR_WIDTH = 550;

export const ACTIVE_FILTERS = 'activeFilters';
export const FILTERS = 'Filters';

const typesArray = [SET_SUBSCRIPTIONS, SET_ACTIVE_FILTERS, SET_FILTERS ];

export const TYPES = createTypes(SUBSCRIPTIONS_RK, typesArray);
