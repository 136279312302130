export const ALL = 'Tous';
export const CONTRACT = 'Membre ayant un contrat';
export const ACCOUNT = 'Membre n\'ayant qu\'un compte';

export const ALL_VALUE = "";
export const CONTRACT_VALUE = 'true';
export const ACCOUNT_VALUE = 'false';

export const ALL_CLASSNAME = 'all';
export const CONTRACT_CLASSNAME = 'contract';
export const ACCOUNT_CLASSNAME = 'account';


export const statusTypes = [
	{
		name: ALL,
		value: ALL_VALUE,
		className: ALL_CLASSNAME,
	},
	{
		name: CONTRACT,
		value: CONTRACT_VALUE,
		className: CONTRACT_CLASSNAME,
	},
	{
		name: ACCOUNT,
		value: ACCOUNT_VALUE,
		className: ACCOUNT_CLASSNAME,
	},
];

export const statusValueToClassNameMapper = {
	[ALL_VALUE]: ALL_CLASSNAME,
	[ACCOUNT_VALUE]: ACCOUNT_CLASSNAME,
	[CONTRACT_VALUE]: CONTRACT_CLASSNAME,

};



export const labelSize = { labelSize: 5 };

export const propertyToLabelMapper = {
	status: 'Etat/Status',
};
