import { SET_MEMBERS,TYPES} from './constants';
import { createReducer } from 'redux-friends';

const defaultState = []

const behaviors = {
	[TYPES[SET_MEMBERS]]: (state, { payload }) => payload,
};

export default createReducer(behaviors, defaultState);
