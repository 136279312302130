import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createHistory from 'history/createHashHistory';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import rootReducer from './root.reducer';

export const history = createHistory();
const middlewares = [routerMiddleware(history), thunk];

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));
