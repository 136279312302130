import { fetch } from '../api';

const endPoints = {
	login: '/api/login_check',
	logout: '/api/logout',
};

export const login = (username, password) =>
	fetch(`${endPoints.login}`, 'post', {
		email: username,
		password,
	});

export const ping = () => fetch(`/api/me`, 'get');
