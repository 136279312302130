import {
	ACTIVE_FILTERS,
	SET_SUBSCRIPTIONS,
	TYPES,
	SET_ACTIVE_FILTERS,
	SET_FILTERS,
	FILTERS,
} from './constants';
import {
	createReducer,
	assignPayloadToKey,
	PAGES,
	DATA,
	spreadPayloadToState,
} from 'redux-friends';

const defaultState = {
	[ACTIVE_FILTERS]: [],
	[DATA]: [],
	[PAGES]: 1,
	[FILTERS]: [],
};

const behaviors = {
	[TYPES[SET_ACTIVE_FILTERS]]: assignPayloadToKey(ACTIVE_FILTERS),
	[TYPES[SET_FILTERS]]: assignPayloadToKey(FILTERS),
	[TYPES[SET_SUBSCRIPTIONS]]: spreadPayloadToState,
};

export default createReducer(behaviors, defaultState);
