import { createTypes } from 'redux-friends';

export const CUSTOMERS_RK = 'customers';

export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_ACTIVE_FILTERS = 'SET_ACTIVE_FILTERS';
export const SET_FILTERS = 'SET_FILTERS';

export const CUSTOMERS_SIDEBAR_ID = 'customer';
export const SUBSCRIPTION_SIDEBAR_WIDTH = 550;

export const ACTIVE_FILTERS = 'activeFilters';
export const FILTERS = 'Filters';

const typesArray = [SET_CUSTOMERS, SET_ACTIVE_FILTERS, SET_FILTERS];

export const TYPES = createTypes(CUSTOMERS_RK, typesArray);
