import {
	SET_SIDEBAR_ID,
	SET_SIDEBAR_OPEN,
	SET_SIDEBAR_WIDTH,
	TYPES,
	OPEN,
	ID,
	MENU_WIDTH,
} from './constants';
import { createReducer, assignPayloadToKey } from 'redux-friends';

const defaultState = {
	[OPEN]: false,
	[ID]: null,
	[MENU_WIDTH]: 280,
};

const behaviors = {
	[TYPES[SET_SIDEBAR_ID]]: assignPayloadToKey(ID),
	[TYPES[SET_SIDEBAR_OPEN]]: assignPayloadToKey(OPEN),
	[TYPES[SET_SIDEBAR_WIDTH]]: assignPayloadToKey(MENU_WIDTH),
};

export default createReducer(behaviors, defaultState);
