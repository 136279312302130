import { createTypes } from 'redux-friends';
// -------------reducer entry key ---------------- //
export const DOCUMENTS_RK = 'documents';
// ---------------- actions Types  ----------------- //
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SET_DOCUMENT_UPLOAD_ERROR = 'SET_DOCUMENT_UPLOAD_ERROR';
// --------- reducer keys -------------- //
export const DOCUMENTS = 'data';
export const UPLOAD_ERROR = 'error';
// --------- types Array -------------- //
export const MyTypesArray = [SET_DOCUMENTS, SET_DOCUMENT_UPLOAD_ERROR];
// -------------- types ---------------- //
export const TYPES = createTypes(DOCUMENTS_RK, MyTypesArray);
