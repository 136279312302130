import { uploadDocument, fetchDocuments, setDocumentUploadError } from './actions';
import { getDocuments, getDocumentsUploadError } from './selectors';
import DOCUMENT_REDUCER from './reducer';
import { DOCUMENTS_RK } from './constants';

export {
	DOCUMENTS_RK,
	DOCUMENT_REDUCER,
	uploadDocument,
	getDocuments,
	fetchDocuments,
	getDocumentsUploadError,
	setDocumentUploadError,
};
