import {
	SET_SIDEBAR_OPEN,
	SET_SIDEBAR_ID,
	SET_SIDEBAR_WIDTH,
	TYPES,
} from './constants';
import { createAction } from 'redux-friends';

export const setSidebarOpen = createAction(TYPES[SET_SIDEBAR_OPEN]);
export const setSidebarID = createAction(TYPES[SET_SIDEBAR_ID]);
export const setSidebarWidth = createAction(TYPES[SET_SIDEBAR_WIDTH]);
