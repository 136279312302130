import { formatDataFromSymfony } from '@whatsonweb/react-utils';
import { createAction } from 'redux-friends';
import {
	TYPES,
	SET_ACTIVE_FILTERS,
	SET_FILTERS,
	SET_CUSTOMERS
} from './constants';
import * as customerService from '../../../services/member';

export const setCustomers = createAction(TYPES[SET_CUSTOMERS]);
export const setActiveFilters = createAction(TYPES[SET_ACTIVE_FILTERS]);
export const setFilters = createAction(TYPES[SET_FILTERS]);

export const fetchCustomers = state => async dispatch => {
	try {
		const data = await customerService.search(state);
		dispatch(setCustomers(setCustomers(data)));
	} catch (error) {
		console.error(error);
	}
};
