import {
	SET_DOCUMENTS,
	SET_DOCUMENT_UPLOAD_ERROR,
	TYPES,
	UPLOAD_ERROR,
	DOCUMENTS,
} from './constants';
import { createReducer, assignPayloadToKey } from 'redux-friends';

const defaultState = { [DOCUMENTS]: [], [UPLOAD_ERROR]: false };

const behaviors = {
	[TYPES[SET_DOCUMENTS]]: assignPayloadToKey(DOCUMENTS),
	[TYPES[SET_DOCUMENT_UPLOAD_ERROR]]: assignPayloadToKey(UPLOAD_ERROR),
};

export default createReducer(behaviors, defaultState);
