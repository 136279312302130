import { createTypes } from 'redux-friends';
// -------------reducer entry key ---------------- //
export const LEFT_SIDEBAR_RK = 'sidebar';
// ---------------- actions Types  ----------------- //
export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN';
export const SET_SIDEBAR_ID = 'SET_SIDEBAR_ID';
export const SET_SIDEBAR_WIDTH = 'SET_SIDEBAR_WIDTH';
// ------------ reducer keys -------------------- //
export const OPEN = 'open';
export const ID = 'id';
export const FILTERS = 'filters';
export const MENU_WIDTH = 'width';
// --------- types Array -------------- //
export const MyTypesArray = [
	SET_SIDEBAR_OPEN,
	SET_SIDEBAR_ID,
	SET_SIDEBAR_WIDTH,
];
// -------------- types ---------------- //
export const TYPES = createTypes(LEFT_SIDEBAR_RK, MyTypesArray);
