import React from 'react';

import { SUBSCRIPTION_SIDEBAR_ID } from '../../modules/Subscriptions/redux/constants';
import { CUSTOMERS_SIDEBAR_ID } from '../../modules/Customers/redux/constants';

import SubscriptionsFilters from '../../modules/Subscriptions/Filters/';
import CustomersFilters from '../../modules/Customers/Filters/';

export const DEFAULT_COMPONENT = () => <div>Ce contenu devrait rester caché ;)</div>;
export const DEFAULT_WIDTH = 280;

export const mapper = {
	[SUBSCRIPTION_SIDEBAR_ID]: SubscriptionsFilters,
	[CUSTOMERS_SIDEBAR_ID]: CustomersFilters,
};
