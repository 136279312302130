import moment from 'moment';

const extractYear = date => moment(date).format('YYYY');

export const getYearsFromSubscriptions = subs =>
	subs.map(({ startDate }) => {
		const year = extractYear(startDate);
		return { name: year, value: year };
	});


export const convertItemAsValuesCollectionByMappingProperties = (filters, mapper) => Object.keys(filters).map(key => mapper[key]);

export const formatData = (initialvalues)  => ({
	...initialvalues,
	'insured.id': initialvalues['test'],
});

export default null;
