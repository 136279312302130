import { formatDataFromSymfony } from '@whatsonweb/react-utils';
import { createAction } from 'redux-friends';
import { SET_MEMBERS, TYPES } from './constants';
import * as memberService from '../../../services/member';


export const setMembers = createAction(TYPES[SET_MEMBERS]);

export const fetchMembers = (state = {}) => async dispatch => {
    try {
		const members = (await memberService.search(state))['hydra:member']
		dispatch(setMembers(members));
    } catch (error) {
        console.warn(error);
    }
};



