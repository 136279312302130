import request from 'superagent';
import * as session from '../../services/session';
import ApiConfig from '../../constants/ApiConfig';

const prepareValidationError = error => {
	if (!error) {
		return error;
	}

	if (error['hydra:description']) {
		if (error.violations) {
			error._error = '';

			error.violations.map(el => {
				/**
				 * Todo : Problème de validation sur les champs d'entité liée
				 * Il trouve bien l'erreur mais lors du focus sur le champ, il ne parvient pas à le vider et
				 * provoque une erreur
				 * En attentant on remonte tous les messages, mais les champs ne sont pas colorés
				 */

				error[el['propertyPath']] = el.message;
				error._error += `${el.message}<br />`;

				/*
                let test = el['propertyPath'].split('.'); 
                
                if(test.length == 2) {
                    
                    if(!error[test[0]]) error[test[0]] = []; 
                    error[test[0]][test[1]] = el.message; 
                    error._error += el.message + '<br />';
                }else{

    

                }
                */
			});
		} else {
			error._error = error['hydra:description'];
		}
	} else {
		if (error.code && error.code == 401) {
			if (error.message == 'Bad credentials') {
				error._error =
					'Vous n’avez pas été reconnu. Merci de réessayer ou de contacter votre administrateur. ';
			}
		}
	}

	return error;
};

export const fetch = (url, method = 'GET', data = {}, files = {}) =>
	new Promise((resolve, reject) => {
		method = method.toUpperCase();

		if (!url.match(/^http/)) {
			url = `${ApiConfig.url}${url}`;
		}

		let query = request(method, url);

		switch (method) {
			case 'GET':
				query.query(data);
				break;

			case 'POST':
			case 'PUT':
			case 'DELETE':
				query.send(data);
				break;

			case 'UPLOAD':
				query = request.post(url);

				Object.keys(files).forEach(key => {
					const file = files[key];
					query.attach('file', file);
				});
				break;
		}

		const jwtToken = session.getJwtToken();
		if (jwtToken) {
			query.set('Authorization', `Bearer ${jwtToken}`);
		}

		query
			.then(data => {
				resolve(data.body);
			})
			.catch(error => {
				if (error.response) {
					reject(prepareValidationError(error.response.body));
				} else {
					console.log(error);
					reject(error);
				}
			});
	});
