import { formatDataFromSymfony } from '@whatsonweb/react-utils';
import { createAction } from 'redux-friends';
import { SET_SUBSCRIPTIONS, TYPES, SET_ACTIVE_FILTERS, SET_FILTERS } from './constants';
import * as subscriptionService from '../../../services/subscription';

export const setSubscriptions = createAction(TYPES[SET_SUBSCRIPTIONS]);
export const setActiveFilters = createAction(TYPES[SET_ACTIVE_FILTERS]);
export const setFilters = createAction(TYPES[SET_FILTERS]);

export const fetchSubscriptions = state => async dispatch => {
	try {
		const data = await subscriptionService.search(state);
		dispatch(setSubscriptions(formatDataFromSymfony(data)));
	} catch (error) {
		console.error(error);
	}
};

export const updateSubscription = updatedSubscription => async dispatch => {
	try {
		await subscriptionService.update(updatedSubscription);
	} catch (error) {
		console.error(error);
	}
};