const defaultUser = {
	id: null,
	name: null,
	email: null,
	username: null,
	mobile: null,
	picture: null,
	roles: null,
};

const user = (state = defaultUser, action) => {
	switch (action.type) {
		case 'SET_USER':
			return {
				...state,
				id: action.user.id,
				name: action.user.name,
				lastname: action.user.lastname,
				firstname: action.user.firstname,
				username: action.user.username,
				email: action.user.email,
				mobile: action.user.mobile,
				roles: action.user.roles,
			};

		case 'SET_ORGANISATION':
			return {
				...state,
			};

		default:
			return state;
	}
};

export default user;
