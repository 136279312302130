import { SET_DOCUMENTS, TYPES, SET_DOCUMENT_UPLOAD_ERROR } from './constants';
import { createAction } from 'redux-friends';
import * as documentsService from '../../../services/documents';

export const setDocuments = createAction(TYPES[SET_DOCUMENTS]);
export const setDocumentUploadError = createAction(TYPES[SET_DOCUMENT_UPLOAD_ERROR]);

export const fetchDocuments = () => async dispatch => {
	try {
		const data = await documentsService.getAll();
		dispatch(setDocuments(data['hydra:member']));
	} catch (error) {
		console.warn(error);
	}
};

export const uploadDocument = (newFile, documentId) => async dispatch => {
	try {
		await documentsService.update({ id: documentId, file: newFile });
		fetchDocuments()(dispatch);
	} catch (error) {
		dispatch(setDocumentUploadError(true));
	}
};
