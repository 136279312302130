import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSidebarID, setSidebarOpen } from './redux/';

import { mapper, DEFAULT_COMPONENT } from './constants';

export const LeftSidebarContent = ({ id, onClose }) => {
	const Compo = mapper[id] || DEFAULT_COMPONENT;
	return <Compo onClose={onClose} />;
};

LeftSidebarContent.defaultProps = { id: null };
LeftSidebarContent.propTypes = {
	id: PropTypes.string,
	onClose: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({ id: getSidebarID(state) });

export const mapDispatchToProps = dispatch =>
	bindActionCreators({ onClose: () => setSidebarOpen(false) }, dispatch);

export default connect(mapStateToProps,
	mapDispatchToProps)(LeftSidebarContent);
