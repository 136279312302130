import { createApiStuff } from '@whatsonweb/react-utils';

import ApiConfig from '../constants/ApiConfig';

export const {
	fetch,
	me,
	account,
	password,
	ping,
	login,
	logout,
	checkRole,
	isAuthenticated,
	getUser,
	testRole,
	createMethods,
} = createApiStuff(ApiConfig);
