import moment from 'moment';

const extractYear = date => moment(date).format('YYYY');

export const getYearsFromSubscriptions = subs =>
	subs.map(({ startDate }) => {
		const year = extractYear(startDate);
		return { name: year, value: year };
	});


export const convertItemAsValuesCollectionByMappingProperties = (filters, mapper) => Object.keys(filters).map(key => mapper[key]);

export default null;
